import React, { Component } from "react";
import "./InputBar.css";

export default class InputBar extends Component {
	render() {
		const { label, onChange } = this.props;
		const inputKey = label
			.split(" ")[0]
			.toLowerCase();
		const inputId = inputKey + "-input";
		return (
			<div className="input-bar">
				<label htmlFor={inputId}>{label}</label>
				<input id={inputId} onChange={event => {
					onChange(event, inputKey);
				}}></input>
			</div>
		);
	}
}
