import React, { Component } from "react";
import "./TextBox.css";

export default class TextBox extends Component {
	render() {
		const { label, onChange } = this.props;
		const inputKey = label.toLowerCase();
		return (
			<textarea
				className="text-box"
				placeholder={label}
				aria-label={"Enter " + inputKey + " here"}
				onChange={event => {
					onChange(event, inputKey);
				}}>
			</textarea>
		);
	}
}
