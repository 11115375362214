import React, { Component } from "react";
import InputBar from "./InputBar";
import TextBox from "./TextBox";
import "./PostInput.css";

export default class PostInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: null,
			username: null,
			content: null,
			image: null
		};
	}

	render() {
		return (
			<div className="post-input">
				<h1>New Post</h1>
				<InputBar
					label="Title"
					onChange={(event, inputKey) => {
						this.updateValue(event, inputKey);
					}}
				/>
				<InputBar
					label="Username"
					onChange={(event, inputKey) => {
						this.updateValue(event, inputKey);
					}}
				/>
				<TextBox
					label="Content"
					onChange={(event, inputKey) => {
						this.updateValue(event, inputKey);
					}}
				/>
				<InputBar
					label="Image Link (Optional)"
					onChange={(event, inputKey) => {
						this.updateValue(event, inputKey);
					}}
				/>
				<div className="button-bar">
					<button onClick={() => {
						this.post();
					}}>Post</button>
				</div>
			</div>
		);
	}

	post() {
		fetch("https://general-assignment.shangzhen.workers.dev/posts", {
			body: JSON.stringify({
				title: this.state.title,
				username: this.state.username,
				content: this.state.content,
				image: this.state.image
			}),
			credentials: "include",
			headers: {
				"Content-Type": "application/json"
			},
			method: "POST"
		})
			.then(response => response.text())
			.then(data => {
				if (data) {
					if (data === "success") {
						alert("Posted successfully!");
						window.location.reload();
					} else {
						alert("Error: " + data);
					}
				}
			})
			.catch(error => {
				console.error(error);
			});
	}

	updateValue(event, key) {
		this.setState({
			[key]: event.target.value
		});
	}
}
