import PostInput from "./components/PostInput";
import PostList from "./components/PostList";
import './App.css';

function App() {
  return (
    <div className="app">
      <PostInput />
      <PostList />
    </div>
  );
}

export default App;
