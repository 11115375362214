import React, { Component } from "react";
import "./Tabs.css";

export default class Tabs extends Component {
	render() {
		const { active, items, setActiveTab } = this.props;
		const tabs = [];
		for (let i = 0; i < items.length; i++) {
			const item = items[i];
			tabs.push(
				<div
					className={item === active ? "active" : ""}
					key={item}
					role="button"
					onClick={() => {
						setActiveTab(item);
					}}>
					{item}
				</div>
			);
		}
		return (
			<div className="tabs">{tabs}</div>
		);
	}
}
