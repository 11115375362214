import React, { Component } from "react";
import "./Card.css";

export default class Card extends Component {
	constructor(props) {
		super(props);
		this.state = {
			comments: this.props.post.comments || [],
			vote: this.props.post.vote || 0
		};
	}

	render() {
		const { post } = this.props;
		const comments = [];
		for (let i = 0; i < this.state.comments.length; i++) {
			comments.push(<div key={i}>{this.state.comments[i]}</div>);
		}
		return (
			<div className="card">
				<div className="main">
					<div className="like-count">{this.state.vote} vote(s)</div>
					<h1>{post.title}<span className="username">@{post.username}</span></h1>
					<div className="content">{post.content}</div>
					<img src={post.image} style={post.image ? {} : {
						display: "none"
					}} alt="Posted by user"></img>
				</div>
				<div className="toolbar">
					<div role="button" onClick={() => {
						this.vote(post.index, 1);
					}}>Like</div>
					<div role="button" onClick={() => {
						this.vote(post.index, -1);
					}}>Dislike</div>
					<div role="button" onClick={() => {
						this.comment(post.index);
					}}>Comment</div>
				</div>
				<div className="comments" hidden={this.state.comments.length === 0}>
					{comments}
				</div>
			</div>
		);
	}

	vote(index, value) {
		fetch("https://general-assignment.shangzhen.workers.dev/interaction", {
			body: JSON.stringify({
				action: "vote",
				index: index,
				value: value
			}),
			headers: {
				"Content-Type": "application/json"
			},
			method: "POST"
		})
			.catch(error => {
				console.error(error);
			});
		this.setState({
			vote: this.state.vote + value
		});
	}

	comment(index) {
		const comment = prompt("Enter your comment");
		if (!comment) {
			return;
		}
		fetch("https://general-assignment.shangzhen.workers.dev/interaction", {
			body: JSON.stringify({
				action: "comment",
				index: index,
				value: comment
			}),
			headers: {
				"Content-Type": "application/json"
			},
			method: "POST"
		})
			.then(response => response.text())
			.then(data => {
				if (data) {
					if (data === "success") {
						alert("Commented successfully!");
						this.setState({
							comments: [...this.state.comments, comment]
						});
					} else {
						alert("Error: " + data);
					}
				}
			})
			.catch(error => {
				console.error(error);
			});
	}
}
