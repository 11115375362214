import React, { Component } from "react";
import Card from "./Card";
import Tabs from "./Tabs";

export default class PostList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: "All",
			posts: []
		}
	}

	render() {
		const cards = [];
		for (let i = 0; i < this.state.posts.length; i++) {
			const { activeTab, posts } = this.state;
			const post = posts[i];
			post.index = i;
			if (activeTab === "All" || (activeTab === "Images" && post.image)) {
				cards.push(<Card key={i} post={post} />)
			}
		}
		return (
			<div className="post-list">
				<Tabs
					active={this.state.activeTab}
					items={["All", "Images"]}
					setActiveTab={tabName => {
						this.setState({
							activeTab: tabName
						});
					}}
				/>
				<div>{cards}</div>
			</div>
		);
	}

	componentDidMount() {
		this.getPosts();
	}

	getPosts() {
		fetch("https://general-assignment.shangzhen.workers.dev/posts")
			.then(response => {
				if (response.ok) {
					return response.json()
				}
			})
			.then(data => {
				if (data) {
					this.setState({
						posts: data
					});
				}
			})
			.catch(error => {
				console.error(error);
			});
	}
}
